import { RlSelect } from "./rl-select";
var FormBuilder = /** @class */ (function () {
    function FormBuilder() {
        this.initPasswordInputs();
        this.initSelect();
        this.initForm();
    }
    FormBuilder.prototype.initPasswordInputs = function () {
        var _this = this;
        var inputs = document.querySelectorAll('input[type="password"]');
        inputs.forEach(function (input) {
            _this.initPasswordInput(input);
        });
    };
    FormBuilder.prototype.initPasswordInput = function (input) {
        var parentElement = input.parentElement;
        if (!parentElement) {
            return;
        }
        var passwordIcon = parentElement.querySelector(".password-view-icon");
        if (!passwordIcon) {
            return;
        }
        passwordIcon.addEventListener("click", function () {
            var newType = input.type === "password" ? "text" : "password";
            var isPassword = newType === "password";
            input.type = newType;
            passwordIcon.classList.toggle("fa-eye", isPassword);
            passwordIcon.classList.toggle("fa-eye-slash", !isPassword);
        });
    };
    FormBuilder.prototype.initForm = function () {
        var _this = this;
        var form = document.querySelector("form");
        if (!form) {
            return;
        }
        var submitButton = form.querySelector('button[type="submit"]');
        form.addEventListener("submit", function () {
            form.classList.toggle("form-submitting", true);
            if (submitButton && !submitButton.disabled) {
                setTimeout(function () {
                    submitButton.disabled = true;
                    submitButton.innerHTML = '<i class="fad fa-spin fa-spinner-third"></i>';
                }, 100);
            }
        });
        if (submitButton) {
            var buttons = form.querySelectorAll('button[type="button"]');
            buttons.forEach(function (button) {
                button.addEventListener("click", function () {
                    if (button.name && button.value) {
                        button.disabled = true;
                        submitButton.disabled = true;
                        var hiddenInput = document.createElement("input");
                        hiddenInput.type = "hidden";
                        hiddenInput.name = button.name;
                        hiddenInput.value = button.value;
                        form.insertAdjacentElement("beforeend", hiddenInput);
                    }
                    form.submit();
                });
            });
        }
        var errorSection = document.querySelector(".form-errors");
        var inputs = form.querySelectorAll("input");
        inputs.forEach(function (element) {
            if (element.type === "password" || element.type === "text" || element.type === "tel") {
                _this.initInput(form, element, errorSection);
            }
        });
    };
    FormBuilder.prototype.initInput = function (form, input, errorSection) {
        var _this = this;
        input.dataset["pristine"] = "true";
        input.addEventListener("input", function () {
            if (input.dataset["pristine"] === "true") {
                input.dataset["pristine"] = "false";
                _this.removeClassRecursively(input, "has-error");
                if (errorSection && form.getElementsByClassName("has-error").length === 0) {
                    errorSection.remove();
                }
            }
        });
    };
    FormBuilder.prototype.removeClassRecursively = function (element, className) {
        element.classList.remove(className);
        if (element.parentElement) {
            this.removeClassRecursively(element.parentElement, className);
        }
    };
    FormBuilder.prototype.initSelect = function () {
        var rlSelects = document.querySelectorAll(".rl-select");
        rlSelects.forEach(function (element) {
            new RlSelect(element);
        });
    };
    return FormBuilder;
}());
window.addEventListener("load", function () {
    new FormBuilder();
});
