var LinkBuilder = /** @class */ (function () {
    function LinkBuilder() {
        this.initAnchorTags();
    }
    LinkBuilder.prototype.initAnchorTags = function () {
        var _this = this;
        var aTags = document.querySelectorAll("a");
        aTags.forEach(function (element) {
            element.addEventListener("click", _this.anchorTagClicked);
        });
    };
    LinkBuilder.prototype.anchorTagClicked = function (ev) {
        var _this = this;
        ev.preventDefault();
        if (this.classList.contains("btn")) {
            // create loader
            if (!this.dataset["originalHTML"]) {
                this.dataset["originalHTML"] = this.innerHTML;
            }
            this.innerHTML = '<i class="fad fa-spin fa-spinner-third"></i>';
            document.addEventListener("visibilitychange", function () {
                _this.innerHTML = _this.dataset["originalHTML"];
            });
        }
        var url = this.getAttribute("href");
        if (url === "#") {
            if (this.id === "redirect-back") {
                history.back();
            }
        }
        else {
            window.location.assign(url);
        }
        return false;
    };
    return LinkBuilder;
}());
window.addEventListener("load", function () {
    new LinkBuilder();
});
