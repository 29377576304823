import { NativeUtils } from "./native-utils";
var IOSBridge = /** @class */ (function () {
    function IOSBridge(nativeBridgeCallback) {
        var _this = this;
        this.basic = {
            getAvailableMethods: function (callback) { return _this.invokeWithResult("basic", "getAvailableMethods", callback); },
            readyForWebClientConfiguration: function (callback) { return _this.invoke("basic", "readyForWebClientConfiguration", callback); },
            appIsReady: function (callback) { return _this.invoke("basic", "appIsReady", callback); },
            getAppInfo: function (callback) { return _this.invokeWithResult("basic", "getAppInfo", callback); },
            reload: function (callback) { return _this.invoke("basic", "reload", callback); },
        };
        this.visual = {
            changeStatusBar: function (callback, data) { return _this.invoke("visual", "changeStatusBar", callback, data); },
        };
        this.biometric = {
            getStatus: function (callback, data) { return _this.invokeWithResult("biometric", "getStatus", callback, data); },
            getPin: function (callback, data) {
                var maxTimeout = 1000 * 60 * 5; // 5 minutes
                _this.invokeWithResult("biometric", "getPin", callback, data, maxTimeout);
            },
            savePin: function (callback, data) {
                var maxTimeout = 1000 * 60 * 5; // 5 minutes
                _this.invokeWithResult("biometric", "savePin", callback, data, maxTimeout);
            },
            clearPin: function (callback, data) {
                var maxTimeout = 1000 * 60; // 1 minute
                _this.invokeWithResult("biometric", "clearPin", callback, data, maxTimeout);
            },
        };
        this.reactor = nativeBridgeCallback.reactor;
    }
    IOSBridge.prototype.isIOSNativeApp = function () {
        if (!navigator || !navigator.userAgent || !navigator.userAgent.length) {
            return false;
        }
        var userAgent = navigator.userAgent.toLowerCase();
        return (userAgent.indexOf("relesys_web_client") !== -1 &&
            typeof window.webkit !== "undefined" &&
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers["NativeBridge_basic"]);
    };
    IOSBridge.prototype.invoke = function (domain, methodName, callback, data) {
        if (data === void 0) { data = null; }
        var messsageHandlerName = "NativeBridge_" + domain;
        var message = {
            method: methodName,
            data: data,
        };
        window.webkit.messageHandlers[messsageHandlerName].postMessage(JSON.stringify(message));
        if (callback) {
            callback(NativeUtils.createResponse());
        }
    };
    IOSBridge.prototype.invokeWithResult = function (domain, methodName, callback, data, maxTimeout) {
        var _this = this;
        if (data === void 0) { data = null; }
        if (maxTimeout === void 0) { maxTimeout = 5000; }
        var eventListenerName = "native." + domain + "." + methodName;
        var timeoutId = null;
        var eventId = this.reactor.addOnceEventListener(eventListenerName, function (resultData) {
            clearTimeout(timeoutId);
            if (callback) {
                callback(NativeUtils.createResponse(resultData));
            }
        });
        timeoutId = setTimeout(function () {
            _this.reactor.removeEventListener(eventListenerName, eventId);
            if (callback) {
                callback(NativeUtils.createFailedResponse());
            }
        }, maxTimeout);
        this.invoke(domain, methodName, null, data);
    };
    return IOSBridge;
}());
export { IOSBridge };
