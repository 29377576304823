import { NativeUtils } from "./native-utils";
var AndroidBridge = /** @class */ (function () {
    function AndroidBridge(nativeBridgeCallback) {
        this.basic = {
            getAvailableMethods: function (callback) {
                var result = window.NativeBridge.basic_getAvailableMethods();
                callback(NativeUtils.createResponse(result));
            },
            readyForWebClientConfiguration: function (callback) {
                window.NativeBridge.basic_readyForWebClientConfiguration();
                callback(NativeUtils.createResponse());
            },
            appIsReady: function (callback) {
                window.NativeBridge.basic_appIsReady();
                callback(NativeUtils.createResponse());
            },
            getAppInfo: function (callback) {
                var result = window.NativeBridge.basic_getAppInfo();
                callback(NativeUtils.createResponse(result));
            },
            reload: function (callback) {
                window.NativeBridge.basic_reload();
                callback(NativeUtils.createResponse());
            },
        };
        this.visual = {
            changeStatusBar: function (callback, data) {
                window.NativeBridge.visual_changeStatusBar(JSON.stringify(data));
                callback(NativeUtils.createResponse());
            },
        };
        this.biometric = {
            getStatus: function (callback, data) {
                var result = window.NativeBridge.biometric_getStatus(JSON.stringify(data));
                callback(NativeUtils.createResponse(result));
            },
            getPin: function (callback, data) {
                var result = window.NativeBridge.biometric_getPin(JSON.stringify(data));
                callback(NativeUtils.createResponse(result));
            },
            savePin: function (callback, data) {
                var result = window.NativeBridge.biometric_savePin(JSON.stringify(data));
                callback(NativeUtils.createResponse(result));
            },
            clearPin: function (callback, data) {
                var result = window.NativeBridge.biometric_clearPin(JSON.stringify(data));
                callback(NativeUtils.createResponse(result));
            },
        };
        this.reactor = nativeBridgeCallback.reactor;
    }
    AndroidBridge.prototype.isAndroidNativeApp = function () {
        if (!navigator || !navigator.userAgent || !navigator.userAgent.length) {
            return false;
        }
        var userAgent = navigator.userAgent.toLowerCase();
        return (userAgent.indexOf("relesys_web_client") !== -1 &&
            userAgent.indexOf("android") !== -1 &&
            typeof window.NativeBridge !== "undefined");
    };
    return AndroidBridge;
}());
export { AndroidBridge };
