import { NativeBridgeCallback } from "./native-bridge-callback";
import { AndroidBridge } from "./android-bridge";
import { IOSBridge } from "./ios-bridge";
import { NativeUtils } from "./native-utils";
var NativeService = /** @class */ (function () {
    function NativeService(initCallback) {
        var _this = this;
        this.isInitialized = false;
        this.nativeBridge = null;
        this.availableMethods = [];
        this.basic = {
            getAvailableMethods: function (callback) { return _this.getAvailableMethods(callback); },
            readyForWebClientConfiguration: function (callback) {
                return _this.invokeNativeMethod("readyForWebClientConfiguration", "basic", _this.nativeBridge.basic.readyForWebClientConfiguration, callback);
            },
            appIsReady: function (callback) {
                return _this.invokeNativeMethod("appIsReady", "basic", _this.nativeBridge.basic.appIsReady, callback);
            },
            getAppInfo: function (callback) {
                return _this.invokeNativeMethod("getAppInfo", "basic", _this.nativeBridge.basic.getAppInfo, callback);
            },
            reload: function (callback) { return _this.invokeNativeMethod("reload", "basic", _this.nativeBridge.basic.reload, callback); },
        };
        this.visual = {
            changeStatusBar: function (callback, data) {
                return _this.invokeNativeMethod("changeStatusBar", "visual", _this.nativeBridge.visual.changeStatusBar, callback, data);
            },
        };
        this.biometric = {
            getStatus: function (callback, data) {
                return _this.invokeNativeMethod("getStatus", "biometric", _this.nativeBridge.biometric.getStatus, callback, data);
            },
            getPin: function (callback, data) {
                return _this.invokeNativeMethod("getPin", "biometric", _this.nativeBridge.biometric.getPin, callback, data);
            },
            savePin: function (callback, data) {
                return _this.invokeNativeMethod("savePin", "biometric", _this.nativeBridge.biometric.savePin, callback, data);
            },
            clearPin: function (callback, data) {
                return _this.invokeNativeMethod("clearPin", "biometric", _this.nativeBridge.biometric.clearPin, callback, data);
            },
        };
        this.init(function (isNative) {
            if (initCallback) {
                initCallback(isNative);
            }
            if (isNative) {
                _this.initNativeColors();
            }
        });
    }
    NativeService.prototype.init = function (callback) {
        if (this.isInitialized) {
            if (this.nativeBridge !== null) {
                callback(true);
            }
            else {
                callback(false);
            }
            return;
        }
        if (!navigator || !navigator.userAgent) {
            this.isInitialized = true;
            callback(false);
            return;
        }
        window.NativeBridgeCallback = new NativeBridgeCallback();
        var androidBridge = new AndroidBridge(window.NativeBridgeCallback);
        var iosBridge = new IOSBridge(window.NativeBridgeCallback);
        if (androidBridge.isAndroidNativeApp()) {
            this.nativeBridge = androidBridge;
        }
        else if (iosBridge.isIOSNativeApp()) {
            this.nativeBridge = iosBridge;
        }
        if (this.nativeBridge) {
            this.initNativeBridge(callback);
        }
        else {
            callback(false);
        }
        this.isInitialized = true;
    };
    NativeService.prototype.initNativeBridge = function (callback) {
        this.basic.getAvailableMethods(function (res) {
            callback(res.isSuccess);
        });
    };
    NativeService.prototype.initNativeColors = function () {
        try {
            var computedStyle = getComputedStyle(document.body);
            var colors = {
                statusBarBackgroundColor: computedStyle.getPropertyValue("--native-statusbar-background-color"),
                statusBarTextColor: computedStyle.getPropertyValue("--native-statusbar-text-color"),
            };
            this.nativeBridge.visual.changeStatusBar(null, colors);
        }
        catch (_a) { }
    };
    NativeService.prototype.invokeNativeMethod = function (methodName, domain, method, callback, data) {
        if (data === void 0) { data = null; }
        if (this.hasNativeMethod(methodName, domain)) {
            try {
                if (data) {
                    method(callback, data);
                }
                else {
                    method(callback);
                }
            }
            catch (ex) {
                console.error(ex);
                if (callback) {
                    callback(NativeUtils.createFailedResponse());
                }
            }
        }
        else {
            console.error("Failed to find native method: " + domain + "." + method);
            if (callback) {
                callback(NativeUtils.createFailedResponse());
            }
        }
    };
    NativeService.prototype.hasNativeMethod = function (methodName, domain) {
        var lowerCaseMethodName = methodName.toLowerCase();
        var lowerCaseDomain = domain.toLowerCase();
        var method = this.availableMethods.find(function (o) {
            return o.domain == lowerCaseDomain && o.methodName == lowerCaseMethodName;
        });
        return method != null;
    };
    NativeService.prototype.getAvailableMethods = function (callback) {
        var _this = this;
        if (this.nativeBridge === null) {
            if (callback) {
                callback(NativeUtils.createFailedResponse());
            }
            return;
        }
        if (this.availableMethods.length) {
            if (callback) {
                callback(NativeUtils.createResponse(this.availableMethods));
            }
            return;
        }
        try {
            this.nativeBridge.basic.getAvailableMethods(function (res) {
                _this.parseAvailableMethods(res);
                if (callback) {
                    callback(NativeUtils.createResponse(_this.availableMethods));
                }
            });
        }
        catch (ex) {
            console.error(ex);
            if (callback) {
                callback(NativeUtils.createFailedResponse());
            }
        }
    };
    NativeService.prototype.parseAvailableMethods = function (res) {
        if (res.data === null) {
            return;
        }
        for (var i = 0; i < res.data.length; i++) {
            var item = res.data[i];
            this.availableMethods.push({
                methodName: item.MethodName.toLowerCase(),
                domain: item.Area.toLowerCase(),
            });
        }
    };
    return NativeService;
}());
export { NativeService };
