require("./link-builder");
require("./form-builder");
import { NativeService } from "./native/native-service";
window.onload = function () {
    window.nativeService = new NativeService(null);
    var redirectInput = document.getElementById("redirect-auto");
    if (redirectInput) {
        var redirectUrl = redirectInput.value;
        window.location.assign(redirectUrl);
    }
};
