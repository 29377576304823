var NativeReactor = /** @class */ (function () {
    function NativeReactor() {
        this.events = {};
    }
    NativeReactor.prototype.registerEvent = function (eventName) {
        var event = new NativeReactorEvent(eventName);
        this.events[eventName] = event;
    };
    NativeReactor.prototype.dispatchEvent = function (eventName, eventArgs) {
        var event = this.events[eventName];
        if (event === undefined) {
            return;
        }
        event.callbacks.forEach(function (o) {
            if (o.callback) {
                o.callback(eventArgs);
            }
            if (o.runOnce) {
                event.deregisterCallback(o.eventId);
            }
        });
    };
    NativeReactor.prototype.addEventListener = function (eventName, callback) {
        var event = this.events[eventName];
        if (event === undefined) {
            console.error('Event "' + eventName + '" has not been registered');
            return;
        }
        return event.registerCallback(callback, false);
    };
    NativeReactor.prototype.addOnceEventListener = function (eventName, callback) {
        var event = this.events[eventName];
        if (event === undefined) {
            console.error('Event "' + eventName + '" has not been registered');
            return;
        }
        return event.registerCallback(callback, true);
    };
    NativeReactor.prototype.removeEventListener = function (eventName, eventId) {
        var event = this.events[eventName];
        if (event === undefined) {
            console.error('Event "' + eventName + '" has not been registered');
            return;
        }
        event.deregisterCallback(eventId);
    };
    return NativeReactor;
}());
export { NativeReactor };
var NativeReactorEvent = /** @class */ (function () {
    function NativeReactorEvent(name) {
        this.name = name;
        this.callbacks = [];
    }
    NativeReactorEvent.prototype.registerCallback = function (callback, runOnce) {
        var id = "_" + Math.random().toString(36).substring(2, 9);
        this.callbacks.push({
            eventId: id,
            callback: callback,
            runOnce: runOnce,
        });
        return id;
    };
    NativeReactorEvent.prototype.deregisterCallback = function (eventId) {
        var index = this.callbacks.findIndex(function (o) { return o.eventId === eventId; });
        if (index !== -1) {
            this.callbacks.splice(index, 1);
        }
    };
    return NativeReactorEvent;
}());
