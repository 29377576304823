var RlSelect = /** @class */ (function () {
    function RlSelect(element) {
        this.keyPressedTimer = null;
        this.keysPressed = [];
        this.isShowing = false;
        this.documentClickListener = null;
        this.input = element.querySelector("input");
        this.selectInput = element.querySelector(".rl-select-input");
        this.overlay = element.querySelector(".rl-select-overlay");
        this.optionContainer = element.querySelector(".rl-select-options");
        this.initOptions();
        this.initListeners();
    }
    RlSelect.prototype.initOptions = function () {
        var _this = this;
        this.options = [];
        var optionElements = Array.from(this.optionContainer.querySelectorAll(".rl-select-option"));
        optionElements.forEach(function (element) {
            var _a;
            _this.options.push({
                name: element.getAttribute("data-name"),
                search: (_a = element.getAttribute("data-search-name")) === null || _a === void 0 ? void 0 : _a.toLowerCase(),
                value: element.getAttribute("data-value"),
                element: element,
            });
        });
        var currentValue = this.input.value;
        this.currentOption = this.options.find(function (o) { return o.value == currentValue; });
    };
    RlSelect.prototype.initListeners = function () {
        var _this = this;
        this.selectInput.addEventListener("click", function () {
            _this.toggleVisibility(true);
        });
        this.options.forEach(function (option, index) {
            option.element.addEventListener("click", function () {
                _this.onOptionClick(option);
            });
            option.element.addEventListener("keydown", function (ev) {
                var key = ev.key.toLowerCase();
                if (key === "enter") {
                    ev.preventDefault();
                    _this.onOptionClick(option);
                }
                else if (key === "arrowup" && index > 0) {
                    ev.preventDefault();
                    _this.options[index - 1].element.focus();
                }
                else if (key === "arrowdown" && index < _this.options.length - 1) {
                    ev.preventDefault();
                    _this.options[index + 1].element.focus();
                }
            });
        });
    };
    RlSelect.prototype.toggleVisibility = function (show) {
        var _this = this;
        if (show === void 0) { show = null; }
        if (show === null) {
            show = !this.isShowing;
        }
        if (show) {
            if (!this.documentClickListener) {
                this.documentClickListener = new AbortController();
                setTimeout(function () {
                    document.addEventListener("click", function (ev) { return _this.onDocumentClick(ev); }, {
                        signal: _this.documentClickListener.signal,
                    });
                }, 10);
            }
            if (!this.documentKeyListener) {
                this.documentKeyListener = new AbortController();
                setTimeout(function () {
                    document.addEventListener("keypress", function (ev) { return _this.onDocumentKeyPress(ev); }, {
                        signal: _this.documentClickListener.signal,
                    });
                }, 10);
            }
            if (!this.isShowing) {
                setTimeout(function () {
                    _this.currentOption.element.focus();
                }, 50);
            }
        }
        else {
            if (this.documentClickListener) {
                this.documentClickListener.abort();
                this.documentClickListener = null;
            }
            if (this.documentKeyListener) {
                this.documentKeyListener.abort();
                this.documentKeyListener = null;
            }
        }
        this.overlay.classList.toggle("show", show);
        this.optionContainer.classList.toggle("show", show);
        this.isShowing = show;
    };
    RlSelect.prototype.onDocumentClick = function (ev) {
        var target = ev.target;
        if (!target) {
            return;
        }
        if (this.hasElementAsParent(target, this.optionContainer) == false) {
            ev.preventDefault();
            this.toggleVisibility(false);
        }
    };
    RlSelect.prototype.onDocumentKeyPress = function (ev) {
        var _this = this;
        try {
            this.keysPressed.push(ev.key.toLowerCase());
            clearTimeout(this.keyPressedTimer);
            this.keyPressedTimer = setTimeout(function () {
                var searchResults = _this.options;
                _this.keysPressed.every(function (v, i) {
                    var minLength = i + 1;
                    var subResults = searchResults.filter(function (o) {
                        return o.search && o.search.length >= minLength && o.search[i] == v;
                    });
                    if (subResults.length) {
                        searchResults = subResults;
                        return true;
                    }
                    return false;
                });
                if (searchResults.length > 0 && searchResults.length != _this.options.length) {
                    searchResults[0].element.focus();
                }
                _this.keysPressed = [];
            }, 250);
        }
        catch (_a) { }
    };
    RlSelect.prototype.hasElementAsParent = function (element, lookupElement) {
        if (element == lookupElement) {
            return true;
        }
        if (!element.parentElement) {
            return false;
        }
        return this.hasElementAsParent(element.parentElement, lookupElement);
    };
    RlSelect.prototype.onOptionClick = function (option) {
        var _a, _b, _c, _d;
        this.input.value = option.value;
        (_a = this.currentOption) === null || _a === void 0 ? void 0 : _a.element.classList.remove("selected");
        this.currentOption = option;
        this.selectInput.querySelector("span").innerHTML = option.name;
        (_b = this.selectInput.querySelector("img")) === null || _b === void 0 ? void 0 : _b.remove();
        (_c = this.selectInput.querySelector("i")) === null || _c === void 0 ? void 0 : _c.remove();
        option.element.classList.add("selected");
        var img = option.element.querySelector("img");
        var icon = option.element.querySelector("i");
        if (img || icon) {
            this.selectInput.insertAdjacentHTML("afterbegin", (_d = img === null || img === void 0 ? void 0 : img.outerHTML) !== null && _d !== void 0 ? _d : icon === null || icon === void 0 ? void 0 : icon.outerHTML);
        }
        this.toggleVisibility(false);
    };
    return RlSelect;
}());
export { RlSelect };
